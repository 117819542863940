@import url('https://rsms.me/inter/inter.css');

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  background-color: white;
}

#root {
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
}

.trails-main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trails-text {
  position: relative;
  width: 100%;
  padding: 5px;
  height: 130px;
  line-height: 110px;
  color: black;
  font-size: 8em;
  font-weight: 800;
  letter-spacing: -9px;
  will-change: transform, opacity;
  overflow: hidden;
  background-color: none;
}

.trails-text > div {
  overflow: hidden;
}
